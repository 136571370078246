<template>
  <div class="checkout-container">
    <div class="edit-div">
      <p
        class="amount"
        :style="{
          color: $store.getters.color.fontColor,
          backgroundColor: $store.getters.color.color1,
        }"
      >
        Setup Page WIP
      </p>
      <!--<p class="error-message" v-if="errorContent != ''">{{ errorContent }}</p>-->
      <!--<br v-if="errorContent != ''" />-->
      <div ref="cardElementContainer"></div>
      <br />
      <br />
      <p>{{ $t("payment.billingAddress") }}</p>
      <input
        id="firstName"
        type="text"
        :placeholder="$t('label.surname')"
        v-model="firstName"
        required
      />
      <input
        id="lastName"
        type="text"
        :placeholder="$t('label.name')"
        v-model="lastName"
        required
      />
      <br />
      <br />

      <input
        id="address1"
        type="text"
        :placeholder="
          $t('label.travelForm.adress') + ' ' + $t('label.line', { number: 1 })
        "
        v-model="address1"
        required
      />
      <br />
      <br />

      <input
        id="address2"
        type="text"
        :placeholder="
          $t('label.travelForm.adress') + ' ' + $t('label.line', { number: 2 })
        "
        v-model="address2"
      />
      <br />
      <br />
      <input
        id="zipcode"
        type="text"
        :placeholder="$t('label.zipCode')"
        v-model="zipcode"
        required
      />
      <input
        id="city"
        type="text"
        :placeholder="$t('label.travelForm.city')"
        v-model="city"
        required
      />
      <br />
      <br />

      <button
        id="checkout-button"
        @click="startCheckout()"
        :disabled="isButtonDisabled"
      >
        {{ $t("payment.submit") }}
      </button>
      <!-- placeholder -->
      <!--<p class="error-message" v-if="errorContent != ''">{{ errorContent }}</p>-->
      <!--<br v-if="errorContent != ''" />-->
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "PaymentSetup",
  mounted() {
    let script = document.createElement("script");
    script.onload = this.setupStripe;
    script.setAttribute("src", "https://js.stripe.com/v3/");
    script.async = true;
    document.head.appendChild(script);
  },
  data() {
    return {
      currentPrice: this.price,
      currentCode: "",
      isLoading: false,
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      zipcode: "",
      city: "",
      agencyId: this.$route.params.id,
    };
  },
  computed: {
    isButtonDisabled: function () {
      return (
        this.isLoading ||
        this.firstName == "" ||
        this.lastName == "" ||
        this.address1 == "" ||
        this.zipcode == "" ||
        this.city == ""
      );
    },
  },
  methods: {
    async setupStripe() {
      if (window.Stripe === undefined) {
        alert(this.$t("payment.loadingError"));
      } else {
        const stripe = window.Stripe(
          (await HandleApi.getStripePublishableKey()).data.publishableKey
        );
        //"pk_test_sz9kiMTA3CLOVlHcHn8GSZ9c00qC6uwwEP"
        //"pk_live_3z2T7jXyHN8s8aFd1634j13200Pj8nAzMz"
        this.stripe = stripe;
        const elements = stripe.elements();
        const cardEl = document.createElement("card-element");
        this.cardElement = elements.create("card");
        this.cardElement.mount(cardEl);
        this.$refs.cardElementContainer.appendChild(cardEl);
      }
    },
    startCheckout() {
      this.$store.dispatch("load", this.$t("loadMessage.checkout"));
      this.isLoading = true;
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: this.cardElement,
          //billing_details: { name: cardholderName.value }
        })
        .then((result) => {
          if (result.error) {
            this.isLoading = false;
            this.$store.dispatch("unload");
            this.$store.dispatch(
              "push_error_message",
              this.$t("payment.incorrectCard")
            );
          } else {
            this.requestSetup(null, result.paymentMethod.id).then(
              this.processFromServer
            );
          }
        })
        .catch((err) => {
          window.console.log(err);
          this.$store.dispatch("unload");
          this.isLoading = false;
          this.$store.dispatch(
            "push_error_message",
            this.$t("payment.unidentified")
          );
        });
    },
    requestSetup(setupIntent, paymentMethod) {
      let data = {};
      data.userId = this.$store.getters.userId;
      data.firstName = this.firstName;
      data.lastName = this.lastName;
      data.address1 = this.address1;
      data.address2 = this.address2;
      data.zipcode = this.zipcode;
      data.city = this.city;
      if (setupIntent) {
        data.setupIntent = setupIntent;
      } else if (paymentMethod) {
        data.paymentMethod = paymentMethod;
      }
      this.$store.dispatch("unload");
      this.$store.dispatch("load", "Setup in progress...");
      return HandleApi.setupDelayedPayments(this.agencyId, data);
    },
    processFromServer(res) {
      if (res.data.success) {
        //this.$store.dispatch("remove_affiliation");
        this.$store.dispatch("unload");
        this.$store.dispatch("push_notification_message", "Setup complete!");
        this.$router.push("/agency/" + this.agencyId);
      } else if (res.data.requires_action) {
        this.stripe
          .handleCardAction(res.data.clientSecret)
          .then(this.processFromStripe);
      } else if (res.data.error) {
        this.isLoading = false;
        this.$store.dispatch("unload");
        alert(this.$t("payment.server"));
      } else {
        this.isLoading = false;
        this.$store.dispatch("unload");
        alert(this.$t("payment.unexpected"));
      }
    },
    processFromStripe(res) {
      if (res.setupIntent) {
        this.requestPayment(res.setupIntent.id).then(this.processFromServer);
      } else {
        this.isLoading = false;
        this.$store.dispatch("unload");
        this.$store.dispatch(
          "push_error_message",
          this.$t("payment.unidentified")
        );
      }
    },
  },
};
</script>

<style scoped>
.checkout-container {
  text-align: center;
}

.edit-div {
  display: inline-block;
  margin: 0 auto;
  text-align: left;
}

.amount {
  text-align: right;
  background-color: #00909f;
  color: white;
  font-weight: bold;
  padding: 10px;
}

#coupon-button {
  margin-left: 10px;
}

#checkout-button {
  margin-left: 10px;
}
</style>
